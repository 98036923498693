import packageInfo from "../package.json";

// Updated by Dockerfile!
const version = {
  packageName: packageInfo.name,
  packageVersion: packageInfo.version,
  buildVersion: "0.0.45",
  commitID: "dc5b98e",
};

export const sentryRelease =
  ((version.buildVersion !== null && version.buildVersion !== "") ? version.buildVersion : version.packageVersion) +
  (version.commitId !== null ? "+"+version.commitID : "");

export default {version, sentryRelease};
